export enum TopicCategory {
    VideoGame,
    Movie,
    Music,
    TvSeries,
    Book,
    Company,
    CPU,
    GPU,
    MobileDevice,
    VideoGameConsole,
    Computer,
    OS,
    Software,
    Person,
    Country,
    Football,
}

export const slugNameToTopicCategoryMap: Record<string, TopicCategory> = {
    games: TopicCategory.VideoGame,
    movies: TopicCategory.Movie,
    music: TopicCategory.Music,
    "tv-series": TopicCategory.TvSeries,
    books: TopicCategory.Book,
    companies: TopicCategory.Company,
    cpus: TopicCategory.CPU,
    gpus: TopicCategory.GPU,
    "mobile-devices": TopicCategory.MobileDevice,
    consoles: TopicCategory.VideoGameConsole,
    computers: TopicCategory.Computer,
    "operating-systems": TopicCategory.OS,
    software: TopicCategory.Software,
    persons: TopicCategory.Person,
    countries: TopicCategory.Country,
    football: TopicCategory.Football,
};
