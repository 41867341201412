import classNames from "classnames";
import Link from "next/link";
import { FC } from "react";

import { BaseProps } from "../models/props/baseProps";

interface Props extends BaseProps {
    size: string;
    link?: string;
}

export const NostwaveLogo: FC<Props> = (props) => {
    const logo = (
        <div className={classNames(props.className, { clickable: Boolean(props.link) })} style={{ width: props.size }}>
            <svg
                viewBox="0 0 603 121"
                fillRule="nonzero"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                style={{ filter: "drop-shadow(0px 0px 1px #000)" }}
            >
                <path
                    d="M221.496 44.256h-20.88v-8c0-.84-.29-1.57-.86-2.2-.58-.63-1.29-.95-2.12-.95h-8.64c-.83 0-1.54.32-2.12.95-.57.63-.86 1.36-.86 2.2v18.05c0 .84.29 1.57.86 2.2.58.62 1.29.94 2.12.94h14.13c12.98.21 19.47 7.12 19.47 20.72v21.82c0 13.82-6.49 20.72-19.47 20.72h-18.52c-12.98 0-19.47-6.9-19.47-20.72v-10.52h20.88v11.31a3.15 3.15 0 0 0 .86 2.19c.58.63 1.29.95 2.12.95h9.74c.83 0 1.54-.32 2.11-.95.58-.62.87-1.36.87-2.19v-19.94a3.14 3.14 0 0 0-.87-2.2c-.57-.63-1.28-.94-2.11-.94h-14.13c-12.98 0-19.47-6.91-19.47-20.72v-20.25c0-13.82 6.49-20.73 19.47-20.73h17.42c12.98 0 19.47 6.91 19.47 20.73v7.53zm-72.53-7.53v63.26c0 13.82-6.49 20.72-19.46 20.72h-20.88c-12.98 0-19.47-6.9-19.47-20.72v-63.26c0-13.82 6.49-20.73 19.47-20.73h20.88c12.97 0 19.46 6.91 19.46 20.73zm-74.57 82.41h-21.97v-84.3c0-.83-.29-1.57-.87-2.2-.57-.62-1.28-.94-2.12-.94h-8.47c-.84 0-1.55.32-2.12.94-.58.63-.87 1.37-.87 2.2v84.3h-21.98v-82.41c0-13.82 6.49-20.73 19.47-20.73h19.47c12.97 0 19.46 6.91 19.46 20.73v82.41zm503.93-18.21h24.33v18.21h-46.31V17.566h46.31v17.9h-24.33v23.86h22.76v17.27h-22.76v24.33zm-347.1-64.67v-18.69h54.95v18.69h-16.49v82.88h-21.97v-82.88h-16.49zm216.33-20.26c12.98 0 19.46 6.91 19.46 20.73v82.41h-21.97v-34.06h-15.86v34.06h-21.98v-82.41c0-13.82 6.49-20.73 19.47-20.73h20.88zm76.76 1.57h21.2l-21.04 101.57h-25.27l-21.04-101.57h21.35l12.4 74.1 12.4-74.1zm-190.58 0h19.47l11.77 76.46 8.79-76.46h21.35l-16.48 101.57h-24.96l-10.2-62.32-10.21 62.32h-24.96l-16.48-101.57h21.35l8.79 76.46 11.77-76.46zm-206.75 84.93v-68.44a3.13 3.13 0 0 0-.86-2.2c-.58-.63-1.28-.94-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v68.44c0 .84.29 1.57.86 2.2.58.63 1.28.94 2.12.94h9.89c.84 0 1.54-.31 2.12-.94a3.13 3.13 0 0 0 .86-2.2zm302.2-34.38h15.86v-34.06a3.14 3.14 0 0 0-.87-2.2 2.74 2.74 0 0 0-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v34.06z"
                    fill="#0180d1"
                />
                <path
                    d="M218.496 41.256h-20.88v-8c0-.84-.29-1.57-.86-2.2-.58-.63-1.29-.95-2.12-.95h-8.64c-.83 0-1.54.32-2.12.95-.57.63-.86 1.36-.86 2.2v18.05c0 .84.29 1.57.86 2.2.58.62 1.29.94 2.12.94h14.13c12.98.21 19.47 7.12 19.47 20.72v21.82c0 13.82-6.49 20.72-19.47 20.72h-18.52c-12.98 0-19.47-6.9-19.47-20.72v-10.52h20.88v11.31a3.15 3.15 0 0 0 .86 2.19c.58.63 1.29.95 2.12.95h9.74c.83 0 1.54-.32 2.11-.95.58-.62.87-1.36.87-2.19v-19.94a3.14 3.14 0 0 0-.87-2.2c-.57-.63-1.28-.94-2.11-.94h-14.13c-12.98 0-19.47-6.91-19.47-20.72v-20.25c0-13.82 6.49-20.73 19.47-20.73h17.42c12.98 0 19.47 6.91 19.47 20.73v7.53zm-72.53-7.53v63.26c0 13.82-6.49 20.72-19.46 20.72h-20.88c-12.98 0-19.47-6.9-19.47-20.72v-63.26c0-13.82 6.49-20.73 19.47-20.73h20.88c12.97 0 19.46 6.91 19.46 20.73zm-74.57 82.41h-21.97v-84.3c0-.83-.29-1.57-.87-2.2-.57-.62-1.28-.94-2.12-.94h-8.47c-.84 0-1.55.32-2.12.94-.58.63-.87 1.37-.87 2.2v84.3h-21.98v-82.41c0-13.82 6.49-20.73 19.47-20.73h19.47c12.97 0 19.46 6.91 19.46 20.73v82.41zm503.93-18.21h24.33v18.21h-46.31V14.566h46.31v17.9h-24.33v23.86h22.76v17.27h-22.76v24.33zm-347.1-64.67v-18.69h54.95v18.69h-16.49v82.88h-21.97v-82.88h-16.49zm216.33-20.26c12.98 0 19.46 6.91 19.46 20.73v82.41h-21.97v-34.06h-15.86v34.06h-21.98v-82.41c0-13.82 6.49-20.73 19.47-20.73h20.88zm76.76 1.57h21.2l-21.04 101.57h-25.27l-21.04-101.57h21.35l12.4 74.1 12.4-74.1zm-190.58 0h19.47l11.77 76.46 8.79-76.46h21.35l-16.48 101.57h-24.96l-10.2-62.32-10.21 62.32h-24.96l-16.48-101.57h21.35l8.79 76.46 11.77-76.46zm-206.75 84.93v-68.44a3.13 3.13 0 0 0-.86-2.2c-.58-.63-1.28-.94-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v68.44c0 .84.29 1.57.86 2.2.58.63 1.28.94 2.12.94h9.89c.84 0 1.54-.31 2.12-.94a3.13 3.13 0 0 0 .86-2.2zm302.2-34.38h15.86v-34.06a3.14 3.14 0 0 0-.87-2.2 2.74 2.74 0 0 0-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v34.06z"
                    fill="#303080"
                />
                <path
                    d="M215.496 38.256h-20.88v-8c0-.84-.29-1.57-.86-2.2-.58-.63-1.29-.95-2.12-.95h-8.64c-.83 0-1.54.32-2.12.95-.57.63-.86 1.36-.86 2.2v18.05c0 .84.29 1.57.86 2.2.58.62 1.29.94 2.12.94h14.13c12.98.21 19.47 7.12 19.47 20.72v21.82c0 13.82-6.49 20.72-19.47 20.72h-18.52c-12.98 0-19.47-6.9-19.47-20.72v-10.52h20.88v11.31a3.15 3.15 0 0 0 .86 2.19c.58.63 1.29.95 2.12.95h9.74c.83 0 1.54-.32 2.11-.95.58-.62.87-1.36.87-2.19v-19.94a3.14 3.14 0 0 0-.87-2.2c-.57-.63-1.28-.94-2.11-.94h-14.13c-12.98 0-19.47-6.91-19.47-20.72v-20.25c0-13.82 6.49-20.73 19.47-20.73h17.42c12.98 0 19.47 6.91 19.47 20.73v7.53zm-72.53-7.53v63.26c0 13.82-6.49 20.72-19.46 20.72h-20.88c-12.98 0-19.47-6.9-19.47-20.72v-63.26c0-13.82 6.49-20.73 19.47-20.73h20.88c12.97 0 19.46 6.91 19.46 20.73zm-74.57 82.41h-21.97v-84.3c0-.83-.29-1.57-.87-2.2-.57-.62-1.28-.94-2.12-.94h-8.47c-.84 0-1.55.32-2.12.94-.58.63-.87 1.37-.87 2.2v84.3H9.996v-82.41c0-13.82 6.49-20.73 19.47-20.73h19.47c12.97 0 19.46 6.91 19.46 20.73v82.41zm503.93-18.21h24.33v18.21h-46.31V11.566h46.31v17.9h-24.33v23.86h22.76v17.27h-22.76v24.33zm-347.1-64.67v-18.69h54.95v18.69h-16.49v82.88h-21.97v-82.88h-16.49zm216.33-20.26c12.98 0 19.46 6.91 19.46 20.73v82.41h-21.97v-34.06h-15.86v34.06h-21.98v-82.41c0-13.82 6.49-20.73 19.47-20.73h20.88zm76.76 1.57h21.2l-21.04 101.57h-25.27l-21.04-101.57h21.35l12.4 74.1 12.4-74.1zm-190.58 0h19.47l11.77 76.46 8.79-76.46h21.35l-16.48 101.57h-24.96l-10.2-62.32-10.21 62.32h-24.96l-16.48-101.57h21.35l8.79 76.46 11.77-76.46zm-206.75 84.93v-68.44a3.13 3.13 0 0 0-.86-2.2c-.58-.63-1.28-.94-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v68.44c0 .84.29 1.57.86 2.2.58.63 1.28.94 2.12.94h9.89c.84 0 1.54-.31 2.12-.94a3.13 3.13 0 0 0 .86-2.2zm302.2-34.38h15.86v-34.06a3.14 3.14 0 0 0-.87-2.2 2.74 2.74 0 0 0-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v34.06z"
                    fill="#a00f80"
                />
                <path
                    d="M212.496 35.256h-20.88v-8c0-.84-.29-1.57-.86-2.2-.58-.63-1.29-.95-2.12-.95h-8.64c-.83 0-1.54.32-2.12.95-.57.63-.86 1.36-.86 2.2v18.05c0 .84.29 1.57.86 2.2.58.62 1.29.94 2.12.94h14.13c12.98.21 19.47 7.12 19.47 20.72v21.82c0 13.82-6.49 20.72-19.47 20.72h-18.52c-12.98 0-19.47-6.9-19.47-20.72v-10.52h20.88v11.31a3.15 3.15 0 0 0 .86 2.19c.58.63 1.29.95 2.12.95h9.74c.83 0 1.54-.32 2.11-.95.58-.62.87-1.36.87-2.19v-19.94a3.14 3.14 0 0 0-.87-2.2c-.57-.63-1.28-.94-2.11-.94h-14.13c-12.98 0-19.47-6.91-19.47-20.72v-20.25c0-13.82 6.49-20.73 19.47-20.73h17.42c12.98 0 19.47 6.91 19.47 20.73v7.53zm-72.53-7.53v63.26c0 13.82-6.49 20.72-19.46 20.72h-20.88c-12.98 0-19.47-6.9-19.47-20.72v-63.26c0-13.82 6.49-20.73 19.47-20.73h20.88c12.97 0 19.46 6.91 19.46 20.73zm-74.57 82.41h-21.97v-84.3c0-.83-.29-1.57-.87-2.2-.57-.62-1.28-.94-2.12-.94h-8.47c-.84 0-1.55.32-2.12.94-.58.63-.87 1.37-.87 2.2v84.3H6.996v-82.41c0-13.82 6.49-20.73 19.47-20.73h19.47c12.97 0 19.46 6.91 19.46 20.73v82.41zm503.93-18.21h24.33v18.21h-46.31V8.566h46.31v17.9h-24.33v23.86h22.76v17.27h-22.76v24.33zm-347.1-64.67V8.566h54.95v18.69h-16.49v82.88h-21.97v-82.88h-16.49zm216.33-20.26c12.98 0 19.46 6.91 19.46 20.73v82.41h-21.97v-34.06h-15.86v34.06h-21.98v-82.41c0-13.82 6.49-20.73 19.47-20.73h20.88zm76.76 1.57h21.2l-21.04 101.57h-25.27l-21.04-101.57h21.35l12.4 74.1 12.4-74.1zm-190.58 0h19.47l11.77 76.46 8.79-76.46h21.35l-16.48 101.57h-24.96l-10.2-62.32-10.21 62.32h-24.96l-16.48-101.57h21.35l8.79 76.46 11.77-76.46zm-206.75 84.93v-68.44a3.13 3.13 0 0 0-.86-2.2c-.58-.63-1.28-.94-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v68.44c0 .84.29 1.57.86 2.2.58.63 1.28.94 2.12.94h9.89c.84 0 1.54-.31 2.12-.94a3.13 3.13 0 0 0 .86-2.2zm302.2-34.38h15.86v-34.06a3.14 3.14 0 0 0-.87-2.2 2.74 2.74 0 0 0-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v34.06z"
                    fill="#ef1e21"
                />
                <path
                    d="M209.496 32.256h-20.88v-8c0-.84-.29-1.57-.86-2.2-.58-.63-1.29-.95-2.12-.95h-8.64c-.83 0-1.54.32-2.12.95-.57.63-.86 1.36-.86 2.2v18.05c0 .84.29 1.57.86 2.2.58.62 1.29.94 2.12.94h14.13c12.98.21 19.47 7.12 19.47 20.72v21.82c0 13.82-6.49 20.72-19.47 20.72h-18.52c-12.98 0-19.47-6.9-19.47-20.72v-10.52h20.88v11.31a3.15 3.15 0 0 0 .86 2.19c.58.63 1.29.95 2.12.95h9.74c.83 0 1.54-.32 2.11-.95.58-.62.87-1.36.87-2.19v-19.94a3.14 3.14 0 0 0-.87-2.2c-.57-.63-1.28-.94-2.11-.94h-14.13c-12.98 0-19.47-6.91-19.47-20.72v-20.25c0-13.82 6.49-20.73 19.47-20.73h17.42c12.98 0 19.47 6.91 19.47 20.73v7.53zm-72.53-7.53v63.26c0 13.82-6.49 20.72-19.46 20.72h-20.88c-12.98 0-19.47-6.9-19.47-20.72v-63.26c0-13.82 6.49-20.73 19.47-20.73h20.88c12.97 0 19.46 6.91 19.46 20.73zm-74.57 82.41h-21.97v-84.3c0-.83-.29-1.57-.87-2.2-.57-.62-1.28-.94-2.12-.94h-8.47c-.84 0-1.55.32-2.12.94-.58.63-.87 1.37-.87 2.2v84.3H3.996v-82.41c0-13.82 6.49-20.73 19.47-20.73h19.47c12.97 0 19.46 6.91 19.46 20.73v82.41zm503.93-18.21h24.33v18.21h-46.31V5.566h46.31v17.9h-24.33v23.86h22.76v17.27h-22.76v24.33zm-347.1-64.67V5.566h54.95v18.69h-16.49v82.88h-21.97v-82.88h-16.49zm216.33-20.26c12.98 0 19.46 6.91 19.46 20.73v82.41h-21.97v-34.06h-15.86v34.06h-21.98v-82.41c0-13.82 6.49-20.73 19.47-20.73h20.88zm76.76 1.57h21.2l-21.04 101.57h-25.27l-21.04-101.57h21.35l12.4 74.1 12.4-74.1zm-190.58 0h19.47l11.77 76.46 8.79-76.46h21.35l-16.48 101.57h-24.96l-10.2-62.32-10.21 62.32h-24.96l-16.48-101.57h21.35l8.79 76.46 11.77-76.46zm-206.75 84.93v-68.44a3.13 3.13 0 0 0-.86-2.2c-.58-.63-1.28-.94-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v68.44c0 .84.29 1.57.86 2.2.58.63 1.28.94 2.12.94h9.89c.84 0 1.54-.31 2.12-.94a3.13 3.13 0 0 0 .86-2.2zm302.2-34.38h15.86v-34.06a3.14 3.14 0 0 0-.87-2.2 2.74 2.74 0 0 0-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v34.06z"
                    fill="#fdd000"
                />
                <path
                    d="M206.496 29.256h-20.88v-8c0-.84-.29-1.57-.86-2.2-.58-.63-1.29-.95-2.12-.95h-8.64c-.83 0-1.54.32-2.12.95-.57.63-.86 1.36-.86 2.2v18.05c0 .84.29 1.57.86 2.2.58.62 1.29.94 2.12.94h14.13c12.98.21 19.47 7.12 19.47 20.72v21.82c0 13.82-6.49 20.72-19.47 20.72h-18.52c-12.98 0-19.47-6.9-19.47-20.72v-10.52h20.88v11.31a3.15 3.15 0 0 0 .86 2.19c.58.63 1.29.95 2.12.95h9.74c.83 0 1.54-.32 2.11-.95.58-.62.87-1.36.87-2.19v-19.94a3.14 3.14 0 0 0-.87-2.2c-.57-.63-1.28-.94-2.11-.94h-14.13c-12.98 0-19.47-6.91-19.47-20.72v-20.25c0-13.82 6.49-20.73 19.47-20.73h17.42c12.98 0 19.47 6.91 19.47 20.73v7.53zm-72.53-7.53v63.26c0 13.82-6.49 20.72-19.46 20.72h-20.88c-12.98 0-19.47-6.9-19.47-20.72v-63.26c0-13.82 6.49-20.73 19.47-20.73h20.88c12.97 0 19.46 6.91 19.46 20.73zm-74.57 82.41h-21.97v-84.3c0-.83-.29-1.57-.87-2.2-.57-.62-1.28-.94-2.12-.94h-8.47c-.84 0-1.55.32-2.12.94-.58.63-.87 1.37-.87 2.2v84.3H.996v-82.41c0-13.82 6.49-20.73 19.47-20.73h19.47c12.97 0 19.46 6.91 19.46 20.73v82.41zm503.93-18.21h24.33v18.21h-46.31V2.566h46.31v17.9h-24.33v23.86h22.76v17.27h-22.76v24.33zm-347.1-64.67V2.566h54.95v18.69h-16.49v82.88h-21.97v-82.88h-16.49zM432.556.996c12.98 0 19.46 6.91 19.46 20.73v82.41h-21.97v-34.06h-15.86v34.06h-21.98v-82.41c0-13.82 6.49-20.73 19.47-20.73h20.88zm76.76 1.57h21.2l-21.04 101.57h-25.27l-21.04-101.57h21.35l12.4 74.1 12.4-74.1zm-190.58 0h19.47l11.77 76.46 8.79-76.46h21.35l-16.48 101.57h-24.96l-10.2-62.32-10.21 62.32h-24.96l-16.48-101.57h21.35l8.79 76.46 11.77-76.46zm-206.75 84.93v-68.44a3.13 3.13 0 0 0-.86-2.2c-.58-.63-1.28-.94-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v68.44c0 .84.29 1.57.86 2.2.58.63 1.28.94 2.12.94h9.89c.84 0 1.54-.31 2.12-.94a3.13 3.13 0 0 0 .86-2.2zm302.2-34.38h15.86v-34.06a3.14 3.14 0 0 0-.87-2.2 2.74 2.74 0 0 0-2.12-.94h-9.89c-.84 0-1.54.31-2.12.94-.57.63-.86 1.36-.86 2.2v34.06z"
                    fill="#fff"
                />
            </svg>
        </div>
    );

    if (props.link) {
        return (
            <Link href={props.link} passHref>
                <a>{logo}</a>
            </Link>
        );
    }

    return logo;
};
