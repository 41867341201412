import { TopicCategory, slugNameToTopicCategoryMap } from "./posts/models/enums/topicCategory";
import { TopicDto } from "./topics/models/topicDto";

const topicCategoryToSlugMap = {} as Record<TopicCategory, string>;
for (const key in slugNameToTopicCategoryMap) {
    const value = slugNameToTopicCategoryMap[key];
    topicCategoryToSlugMap[value] = key;
}

export class AppPath {
    public static signIn = (from?: string) => {
        if (from && from.startsWith("/")) {
            from = from.substr(1);
        }

        if (from) {
            return encodeURI(`/sign-in?from=${from}`);
        }

        return "/sign-in";
    };
    public static signUp = "/sign-up";
    public static externalSignUp = "/external-sign-up";
    public static forgotPassword = "/account/password/forgot";
    public static accountSettings = "/account/settings";

    public static popularTopics = "/popular-topics";

    public static writePost = "/write-post";
    public static feed = (year: number | string) => `/${year}/feed`;
    public static popularPosts = (year: number | string) => `/${year}/posts/popular`;
    public static post = (year: number, username: string, id: string) => `${AppPath.userProfile(year, username)}/posts/${id}`;

    public static topic = (topic: Pick<TopicDto, "id" | "hasPage" | "category" | "epochStartingYear">) => {
        const categorySlug = topicCategoryToSlugMap[topic.category];

        if (topic.hasPage) {
            return AppPath.topicPage(categorySlug, topic.id);
        }

        return AppPath.topicPosts(categorySlug, topic.id);
    };

    public static userProfile = (year: number | string, username: string) => `/${year}/@${username}`;

    public static news = "/news";

    public static notFound = "/404";
    public static privacyPolicy = "/privacy-policy";
    public static support = "/support";
    public static termsAndConditions = "/terms-and-conditions";

    private static topicPage = (categorySlug: string, id: string) => `/${categorySlug}/${id}`;
    private static topicPosts = (categorySlug: string, id: string) => `${AppPath.topicPage(categorySlug, id)}/posts`;
}
