import axios from "axios";
import * as yup from "yup";

import { GenderEnum } from "../../users/models/enums/genderEnum";
import { AuthResultDto } from "../models/dto/authResultDto";

const authAxios = axios.create({ baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL });

export class AuthService {
    public async signUp(
        email: string,
        username: string,
        password: string,
        gender: GenderEnum,
        birthday: Date,
        newsletter: boolean,
        reCaptchaToken: string
    ) {
        return await authAxios.post("user/local/register", {
            email,
            username,
            password,
            gender,
            birthday: birthday.toISOString(),
            newsletter,
            reCaptchaToken,
        });
    }

    public async signIn(id: string, password: string, reCaptchaToken: string) {
        const data = { password, reCaptchaToken, email: undefined as string, username: undefined as string };
        if (this.isEmail(id)) {
            data.email = id;
        } else {
            data.username = id;
        }

        return await authAxios.post<AuthResultDto>("user/local/login", data, { withCredentials: true });
    }

    public async signInWithGoogle(googleTokenId: string) {
        return await authAxios.post<AuthResultDto>("user/external/google", { googleTokenId }, { withCredentials: true });
    }

    public async signInWithFacebook(accessToken: string) {
        return await authAxios.post<AuthResultDto>(
            "user/external/facebook",
            { facebookAccessToken: accessToken },
            { withCredentials: true }
        );
    }

    public async finishSigningIn(token: string, username: string, gender: GenderEnum, birthday: Date, newsletter: boolean) {
        return await authAxios.post<AuthResultDto>(
            "user/external",
            { token, username, gender, birthday: birthday.toISOString(), newsletter },
            { withCredentials: true }
        );
    }

    public async signOut() {
        return await authAxios.post("user/logout", null, { withCredentials: true });
    }

    public async confirm(email: string, code: string) {
        return await authAxios.post<{ result: boolean }>("user/email/confirm", { email, code });
    }

    public async resendConfirmation(email: string, reCaptchaToken: string) {
        return await authAxios.post("user/email/resend", { email, reCaptchaToken });
    }

    public async forgotPassword(email: string, reCaptchaToken: string) {
        return await authAxios.post("user/password/forgot", { email, reCaptchaToken });
    }

    public async resetPassword(token: string, password: string) {
        return await authAxios.post("user/password/reset", { token, password });
    }

    private isEmail(id: string) {
        return yup.string().email().isValidSync(id);
    }
}

export const authService = new AuthService();
